import * as React from "react";
import { PageProps, graphql } from "gatsby";

import Layout from "../components/layout";
import { useTranslation } from "react-i18next";
import { ContentInPolishLanguageAlert } from "../components/ContentInPolishLanguageAlert";

type DataProps = {
  site: {
    buildTime: string;
  };
};

const PrivacyPolicy: React.FC<PageProps<DataProps>> = ({ location }) => {
  const { t } = useTranslation();
  const numberOfChapters = 7;

  return (
    <Layout
      seoTitle={t("privacyPolicy.title")}
      seoDescription={t("privacyPolicy.title")}
      location={location}
    >
      <div className="container">
        <div className="row">
          <div className="col-lg-10 offset-lg-1">
            <section className="page-header">
              <ContentInPolishLanguageAlert what={t("privacyPolicy.title")} />
              <h2>{t("privacyPolicy.title")}</h2>
              <h5>Inspecto Sp. z o.o.</h5>
            </section>
            <section className="privacy-policy-section">
              <div className="row">
                <div className="col-md-3 position-relative">
                  <nav className="nav privacy-policy-nav">
                    {Array(numberOfChapters)
                      .fill("")
                      .map((_, index) => (
                        <a href={`#chapter-${index + 1}`} className="nav-link">
                          {index + 1}.{" "}
                          {t(`privacyPolicy.chapter${index + 1}.header`)}
                        </a>
                      ))}
                  </nav>
                </div>
                <div className="col-md-9">
                  <div className="privacy-policy-card card">
                    <div className="card-body">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t("privacyPolicy.description"),
                        }}
                      />

                      {Array(numberOfChapters)
                        .fill("")
                        .map((_, index) => (
                          <div id={`chapter-${index + 1}`}>
                            <h5 className="policy-title">
                              {index + 1}.{" "}
                              {t(`privacyPolicy.chapter${index + 1}.header`)}
                            </h5>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: t(
                                  `privacyPolicy.chapter${index + 1}.content`
                                ),
                              }}
                            />
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default PrivacyPolicy;

export const query = graphql`
  query ($language: String!) {
    site {
      buildTime(formatString: "YYYY-MM-DD hh:mm a z")
    }
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
